import React from 'react'
import { Row, Col, Container } from 'react-bootstrap';

// Paths to the book covers we want to show
// TODO: dynamically change the image 
var images = [
  "hop_off.jpg",
  "reise.jpg",
  "woerterliebe.jpg",
  "koenig_frosch.jpg",
  "quadriga_liebe.jpg",
  "was_jetzt_anna.jpg",
  "chui_hatari.jpg",
]

export default function Home() {
    return (    
      <div className="black">

        <Container fluid="md">
          <Row>
            <Col>
              <div className="black">
                Willkommen bei Jove Viller. Ich schreibe Bücher seit 2019 und freue mich sehr über Deinen Besuch. Hier findest Du Details zu <a href="/autor" className="black">mir</a> und zu meinen <a href="/buecher" className="black">Büchern</a>.
              </div>
            </Col>
          </Row>
        </Container>

        <Row>
        <Col sm={12} md={6}>
            <a href="/hop-off"><img src={images[0]} alt="bookCover" className="full" /></a> 
          </Col>
          <Col sm={12} md={6}>
            <a href="/reise"><img src={images[1]} alt="bookCover" className="full" /></a> 
          </Col>
          <Col sm={12} md={6}>
            <a href="/woerterliebe"><img src={images[2]} alt="bookCover" className="full" /></a> 
          </Col>
          <Col sm={12} md={6}>
            <a href="/frosch"><img src={images[3]} alt="bookCover" className="full" /></a> 
          </Col>
          <Col sm={12} md={6}>
            <a href="/quadriga"><img src={images[4]} alt="bookCover" className="full" /></a> 
          </Col>
          <Col sm={12} md={6}>
            <a href="/anna"><img src={images[5]} alt="bookCover" className="full" /></a> 
          </Col>
          <Col sm={12} md={6}>
            <a href="/chui-hatari"><img src={images[6]} alt="bookCover" className="full" /></a> 
          </Col>
        </Row>        

      </div>
    );
}