import React from "react";
import { Row, Col, Button } from 'react-bootstrap';

const chapterOne = `Wenn man jung ist, glaubt man oft, dass einem die Dinge nur so zufliegen. Jede Entscheidung, die man trifft, ist die einzig wahre, und gutgemeinte Ratschläge von Älteren – im Besonderen von den Eltern – werden standardmäßig überhört und ausgeblendet. Das ist auch in Ordnung so, sonst würden wir nicht mit diesen lehrreichen Erlebnissen konfrontiert, die uns erwachsen werden lassen. Genau diese Erfahrung machte auch sie in vielen Lebenssituationen. Und was die große Liebe betraf, sollte es für sie eine besonders lange Geschichte werden.

Sie hatte jeden Tag einen weiten Weg zur Schule zurück zu legen. Über eine Stunde war sie unterwegs, mit dem Bus, der Straßenbahn und zu Fuß. Alle ihre Freunde und Freundinnen wohnten in der Nähe der Schule. Deshalb war sie immer allein unterwegs. Da konnte einem schon langweilig werden mit vierzehn. Manchmal vertiefte sie sich in ihre Bücher und nutzte die Zeit schon mal zum Lernen für einen Test. Die eine oder andere Hausübung entstand auch in Schnellschrift im öffentlichen Verkehrsmittel. Dann hatte sie zu Hause mehr Zeit für sich. Manchmal hatte sie aber gar keine Lust auf Arbeit und träumte einfach vor sich hin. Es kam auch vor, dass sie keinen Sitzplatz bekam. So auch an jenem sonnigen Frühlingsnachmittag. 

Die Bahn war gut gefüllt mit Leuten, die von der Arbeit oder, wie sie, von der Schule heimfuhren. Sie kam gerade noch in die Straßenbahn hinein und stand direkt neben dem Fahrer. Da war auch eine Stange als Absperrung, damit man dem Mann nicht zu nahekommen konnte. Aber die nachfolgenden Menschen, die immer noch glaubten, auch mitfahren zu können, schubsten sie so fest, dass ihre Schultasche, die sie in der Hand hielt, schon unter der Stange durchkam. Da sagte der Fahrer, dem das Gedränge sichtlich auf die Nerven ging: „Jetzt sitzt du bald auf meinem Schoß, mein ich!“ „Tut mir sehr leid!“, gab sie freundlich zurück. „Aber ich werde von überall gestoßen. Kann nichts dafür.“ Sie wurde ganz rot, wie das so ist, wenn man nicht gewohnt ist, von einem fremden Mann angesprochen zu werden. Sein unzufriedener Gesichtsausdruck war wie weggeblasen, als er meinte: „Fräulein, das haben wir gleich. Einen Moment!“ Er erhob sich vom Fahrersitz und mit seinen sicherlich 1m 90 stand er da und rief den immer noch nachdrängenden Leuten zu: „Zurücktreten! Der Zug ist voll. Bitte nehmen sie den nachfolgenden Zug, der in wenigen Minuten da sein wird!“ Mit einer eindeutigen Handbewegung untermalte er seine Ansage und endete mit: „Achtung, die Tür wird geschlossen!“ Im nächsten Moment saß er wieder und drückte auf den Knopf. Die Tür schloss sich und er fuhr los. Was für ein Duft, dachte sie. Als er sich so über sie gebeugt hatte, kam er ihr ganz nah und sie spürte ganz stark seinen angenehmen Geruch. Sie wusste nicht, ob es ein After Shave war oder ein Parfum. Jedenfalls hatte das eine Wirkung, die sie so nicht kannte. Es machte ihn total interessant. Völlig egal, dass der Mann mindestens doppelt so alt war wie sie selbst. Je näher sie den Außenbezirken kamen, umso mehr Fahrgäste waren ausgestiegen. Für die letzten Stationen setzte sie sich auf einen Platz, von dem aus sie gut den freundlichen Fahrer beobachten konnte. 

Er hatte auf seiner heutigen Tour eine unterhaltsame Auflockerung gehabt. Das Mädchen war richtig nett. Er wunderte sich, wie frühreif manche Jugendliche heutzutage sind. Er war sich sicher, dass die Kleine mit ihm geflirtet hat. Jedenfalls war er gut gelaunt, als er nach der letzten Runde heim fuhr zu seiner Familie. Er kam gerade recht zum Abendessen. Seit Langem saßen sie wieder einmal alle beisammen, er, seine Frau und die drei Jungs.

Soweit, so gut. Sie vierzehn, er vierunddreißig. Sonnenklar, dass das ihrerseits eine Schwärmerei bleiben musste und für ihn bestenfalls eine knisternde Abwechslung im Dienst war. So blieb es auch die nächsten Jahre. Gelegentliche Plaudereien, wenn man sich zufällig auf der Strecke begegnete und ein bisschen freundschaftliches Geplänkel. Sie hatte auch bald seinen Dienstturnus verstanden und konnte in etwa abschätzen, ob es sich lohne, bei einer Station auch mal auf ihn zu warten. Er bemerkte natürlich das jungmädchenhafte Interesse und fühlte sich irgendwie geschmeichelt. Und so entstand zwischen den beiden ein lockeres Band, das immer wieder für längere Zeit unterbrochen und dann durch einen Knoten wieder verbunden wurde. So konnte er im Laufe der Zeit ihre Verwandlung vom Kind zur jungen Frau beobachten. Er hatte ihr inzwischen von seiner großen Familie erzählt. Seine Söhne seien etwa in ihrem Alter. Also war alles klar. Eine Schwärmerei mit viel Herzklopfen, nicht mehr. 

In der Zeit, als sie zu arbeiten begann und ihren zukünftigen Mann kennenlernte, sah sie ihn lange nicht. Erst, als sie kugelrund einen Babybauch vor sich hertrug, trafen sie sich wieder. Ganz zufällig in einer Straßenbahn. Er sah anders aus, beinahe krank. Sie wollte gar nicht genau wissen, wie viel Gewicht er verloren hatte. Sie fiel aus allen Wolken, als er ihr erzählte, dass er inzwischen geschieden sei. Der Jüngste hat das Nest verlassen, da durfte er gleich mitpacken. „Oh nein!“ sagte sie entsetzt. „Jetzt bin ich verheiratet und du siehst ja…“. Sie deutete mit der Hand auf ihren Bauch. Also schoben beide das Kribbeln im Bauch wieder beiseite… für die nächsten drei Jahre.

Er ging ihr nie ganz aus dem Kopf. Oft war er gegenwärtig, wenn sie zum Beispiel irgendwo das Parfum in die Nase bekam. Mittlerweile wusste sie, dass es Lagerfeld Photo hieß. Dann hielt sie immer Ausschau, ob er nicht irgendwo auftauchte.  Eines Tages sollte es doch wieder passieren. Sie stieg in eine Straßenbahn ein, ganz hinten bei der letzten Tür des Triebwagens. Sie setzte sich hin und dann war er plötzlich wieder da, dieser Wahnsinnsduft. Sofort stand sie auf und ging im Wagen weiter nach vorn. Schon von Weitem erkannte sie ihn an der Statur und an den blonden, etwas schütteren Haaren. Ihr Herz klopfte wie wild. Eigenartig, immer noch, nach so vielen Jahren. Sie stellte sich hinter ihn und suchte im Spiegel seine Augen. An die Aufschrift auf der Tafel über ihm „Bitte während der Fahrt nicht mit dem Fahrer sprechen“ hielt sie sich meistens. Bei der Endstation machte er eine kurze Pause. Sie waren ganz allein bei der Haltestelle. Da wagte er es endlich und fragte sie, ob er sie auf einen Kaffee einladen dürfe. Sie bebte innerlich vor Freude, obwohl sie das eigentlich nicht sollte, und sagte zu. „Was wirst du deinem Mann erzählen?“, fragte er sie. „Das weiß ich noch nicht. Da fällt mir schon was ein.“, antwortete sie nachdenklich, aber sehr erfreut über seine Einladung. 

Das war also ihr erstes Rendezvous. Seit zehn Jahren kannten sie sich nun schon. Die Anziehung zwischen ihnen konnte auch er nicht mehr leugnen. Aber mit ihren 24 Jahren wirkte sie noch immer so unschuldig und irgendwie zerbrechlich. Sie himmelte ihn an und er verehrte sie mehr, als dass er sie verführen wollte. So bestellten sie nur Kaffee und Kuchen in der Konditorei in der Innenstadt. Und dabei blieb es auch. Keiner von beiden traute sich zugeben, dass da bereits mehr im Gange war. Es knisterte nur so und beide hätten sehr gern mehr zugelassen. Aber er schaffte den ersten Schritt nicht. Und sie auch nicht. Nach etwa einer Stunde, die wie im Flug vergangen war, sagte sie leise: „Ich muss gehen. Ich muss nach Hause und noch die Kleine abholen.“

Dann gingen sie beide wieder ihre eigenen Wege. Er hatte es nicht leicht, musste sich nach seiner Scheidung erst wieder alles aufbauen. Das zehrte an seinen Kräften. Aber er schaffte es. Er ordnete sein Leben wieder, hatte auch zwischendurch ein paar kurzzeitige Beziehungen. Aber nichts hielt. Eigentlich wollte er das auch gar nicht wirklich. Sie konnte er aber auch nicht haben. Schließlich war sie verheiratet und zog gerade ihre Tochter groß. Aber manchmal dachte er an sie. 

Jahr um Jahr zog vorüber. Und als ob sie durch überirdische Kräfte verbunden wären, liefen sie sich da und dort über den Weg, obwohl er mittlerweile in einer ganz anderen Gegend mit der Straßenbahn unterwegs war. Dann redeten sie eine Weile miteinander, vergewisserten sich, dass es dem anderen gut ging und das war es dann wieder. Das Band zwischen ihnen riss nie ganz ab. 

Die einzige, mit der sie je darüber sprach, war ihre Schwester, die sich nach den ersten Arbeitsjahren den Traum erfüllte, Straßenbahnfahrerin zu werden. Die sah ihn eine Zeitlang öfter, wenn sie auf seinem Bahnhof aushalf und erzählte ihr natürlich immer davon. Aber dann wurde auch die Schwester Mutter und verlor ihn aus den Augen. 

Als er in Pension ging, sanken die Chancen stark, sich zufällig zu begegnen. Dann half sie schon einmal ein bisschen nach. Sie wusste, wo er wohnte. Das hatte er ihr einmal erzählt. Da war auch ein Einkaufszentrum in der Nähe und sie wusste, dass er sich dort sehr oft seine Zeit vertrieb. Als sie ihn da tatsächlich einmal traf, konnte sie es gar nicht glauben. Es war eine sehr herzliche Begrüßung. Und er nahm endlich seinen ganzen Mut zusammen. Schließlich musste sie jetzt fast 40 sein. ‚Jetzt oder nie‘, sagte er sich und fragte: „Ich bin schon quasi auf dem Heimweg. Möchtest du noch zu mir auf einen Kaffee mitkommen?“ Sie kam vor Aufregung fast ins Stottern und brachte nur hervor: „Hm, ja. Das würde ich gern… Aber ich hab nicht viel Zeit. Ich muss um sechs zu Hause sein.“ „Ist ja nicht weit.“, antwortete er und dann gingen sie in seine Wohnung. Es gefiel ihr gut dort. Etwas wenig Platz zwar, aber für ihn allein reichte es ja. Am besten gefiel ihr die Aussicht vom Balkon im 5. Stock. In einer Richtung sah man die Hochhäuser der Stadt. 

Irgendwie wollte kein sinnvolles Gespräch zustande kommen. Sie war nervös, wahrscheinlich aus mehreren Gründen. Sie stand dem Mann gegenüber, den sie seit mehr als 25 Jahren im Herzen trug. Gleichzeitig war ihr Mann zu Hause und bald würde auch die Tochter nach Hause kommen. Sie musste eigentlich sofort gehen. Er spürte diese Unruhe und sagte verständnisvoll: „Ist nicht gut, wenn man den Zeitdruck im Hinterkopf hat, oder?“ Sie nickte nur. Dann nahm er sie in den Arm, hielt sie ein paar Minuten ganz fest. Sie erwiderte den Druck seiner Arme und sog genüsslich den wohlbekannten Duft in sich auf. Dabei schloss sie die Augen. Diesmal tauschten sie noch ihre Telefonnummern aus und versprachen, wenigstens manchmal zu telefonieren. 

Die nächsten paar Jahre lebte ihre Liebe von den kurzen Telefonaten, die sie führten und von noch kürzeren, fast zufälligen Treffen im Einkaufszentrum. Aber die meiste Zeit war Funkstille. Das Leben musste ja in der Wirklichkeit weitergehen.

Eines Tages passierte etwas, mit dem niemand gerechnet hat. Nicht so früh! Ihr Mann wachte aus dem Schlaf nicht mehr auf. Sein Herz hatte einfach aufgehört zu schlagen. Das traf sie schwerer, als sie je gedacht hätte und es folgte eine harte Zeit. Sie und ihre Tochter waren jetzt diejenigen, die ihr Leben neu ordnen und sortieren mussten. Ihre Schwester half ihr ganz besonders. Und nach einigen Wochen kam sie mit einer Neuigkeit zu ihr: „Stell dir vor, ich hab zufällig deinen Jugendschwarm getroffen, du weißt schon…. Ich habe ihm erzählt, was passiert ist und er hat gemeint, du bräuchtest vielleicht Hilfe.“ Da lächelte sie und fragte: „Hat er schon gesagt, wann er kommen möchte?“ „Noch nicht. Er wartet auf deinen Anruf, versteht es aber, wenn du noch Zeit brauchst.“ 

Jetzt waren sie beide frei füreinander. Nach 30 Jahren! Wer hätte das gedacht! Sie rief ihn ein paar Tage später an und lud ihn ein. Die Flammen, die sie all die Jahre schwach am Lodern halten mussten, waren sofort wieder entfacht. Nun wollten sie sehen, was ihnen die Zukunft noch bringt.`

const chapterTwo = `Vor drei Monaten war sie in die Seniorenresidenz St. Ursula eingezogen. Nach dem Tod ihres Mannes, mit dem sie 63 Jahre verheiratet gewesen war, hatte sie das gemeinsame Einfamilienhaus mit Garten verkauft, denn mit ihren 87 Jahren war ihr die Pflege allein doch zu viel. Sie hatte nur ein paar lieb gewonnene Möbel mitgenommen. In den Apartments der Residenz war sowohl eine komplette Einbauküche vorhanden als auch ein sehr bequemes Bett. So hatte sie einen Ohrensessel, in dem ihr geliebter Heinz immer gesessen hatte, und einen Sekretär mitgebracht. Hierin verwahrte sie außer der aktuellen Korrespondenz und ihrem Laptop auch ein paar Fotos auf. Die vielen gesammelten Fotobücher, ein paar ihr wichtige Romane und Bildbände und auch ihre Ordner mit Dokumenten standen im Regal an der Ostwand des Apartments.

Sie hatte sich inzwischen gut eingelebt und nahm auch an einigen Angeboten des Seniorenzentrums teil. Oft ging sie in den Speisesaal zum Mittagessen, denn nicht immer hatte sie Lust sich selbst etwas zu kochen. Zu den Spielenachmittagen ging sie bei schlechtem Wetter, denn solange die Witterung des angefangenen Herbstes es zuließ, unternahm sie lieber nachmittags einen langen Spaziergang. Dabei träumte sie sich zu ihrem Heinz, denn Spaziergänge waren auch in den letzten zwanzig Jahren eine lieb gewonnene gemeinsame Unternehmung gewesen. Manchmal brachte sie ein paar bunte Blätter, Kastanien oder Nüsse mit aus dem Wald, mit denen sie dann etwas bastelte, das sie ihrem zehnjährigen Enkel bei seinem Besuch schenkte. 

Heute war wieder ein strahlender Tag und sie nahm sich beim Mittagessen vor, eine lange Wanderung im nur ein paar hundert Meter entfernten Wald zu machen. Meist ging sie allein, denn die meisten Bewohner im Heim waren eher Stubenhocker. Als sie los ging, sah sie, dass ein Transporter vor dem Eingang wartete, aus dem zwei Männer Möbel ins Haus trugen. Ein gutaussehender Mann, der sicher in ihrem Alter war, stand dabei und dirigierte die beiden Männer. Es war ihm offensichtlich wichtig, dass den Möbeln beim Tragen keine Schäden zugefügt wurden, denn er ging jeweils vor den beiden Trägern her, hielt die Tür auf und begleitete sie zu seinem Apartment.

Sie grüßte freundlich und ging dann in den Wald. Dort dachte sie über den neuen Mitbewohner nach. Er hatte etwas Ähnlichkeit mit ihrem Heinz, war groß und schlank und sein graues Haar war gepflegt gestutzt. ‚Ob der wohl ein Mensch ist, der auch gern spazieren geht?‘ überlegte sie. ‚Ich muss ihn bei nächster Gelegenheit mal darauf ansprechen.‘ Auf dem Rückweg überquerte sie gerade die Straße vor dem Seniorenstift, als der Mann aus dem Haupteingang kam und mit Baskenmütze und Stock bewaffnet offensichtlich spazieren gehen wollte. „Entschuldigen sie bitte, sie wohnen sicher schon länger hier und können mir einen Tipp geben, wo man hier spazieren gehen kann“, sprach er sie an. Ihr Herz machte einen Sprung und sie errötete leicht. Er sah das und entschuldigte sich noch einmal. „Bitte verzeihen sie meine überfallartige Frage, aber sie sind mir eben beim Möbelausladen aufgefallen und daher habe ich es gewagt, sie gleich einfach anzusprechen.“ Er stellte sich ihr vor, erklärte, dass er jetzt auch im Heim wohnen werde und dass er sich ja hier noch gar nicht auskenne. Sie stellte sich ebenfalls vor und sagte ihm, dass sie seit drei Monaten hier sei und auch gern spazieren gehe. Wenn er einverstanden sei, könnten sie doch ein Stück zusammen gehen. Dann könne sie ihm ein paar Wege zeigen. „Wunderbar, darauf hatte ich gehofft. Aber sie waren doch schon unterwegs. Wird ihnen das nicht zu viel, jetzt noch mal mit mir zugehen?“ „Wir müssen es ja nicht gleich übertreiben, eine kleine Runde schaffe ich noch und danach können sie mich zur Stärkung auf einen Kaffee einladen.“

So gingen die beiden also den Weg zurück, den sie eben gekommen war und sie erklärte ihm einiges in der Umgebung. Er erzählte, dass er mit seiner Frau hatte ins Seniorenheim ziehen wollen und dass sie ihr Haus hatten verkaufen wollen. Dann sei sie plötzlich nach einem Herzinfarkt gestorben und so sei er eben jetzt allein hergezogen. Er erfuhr von ihr, dass ihr Mann auch vor einem Jahr plötzlich verstorben sei und dass sie es dann allein im Haus nicht mehr ausgehalten habe. „Meine Tochter hat mir angeboten, dass ich bei ihr wohnen könne und sie sich auch um mich kümmern wolle. Aber das habe ich abgelehnt. Ich will den Kindern und Enkeln nicht zu Last fallen. Die haben ihr eigenes Leben.“ „Mir trug mein ältester Sohn an, die kleine Einliegerwohnung in seinem Haus zu bekommen. Aber so wie sie hab ich auch empfunden. Meine Frau und ich haben seit meiner Pensionierung alles allein gemanagt. Wieso soll ich plötzlich meine Kinder für mich sorgen lassen wollen. Erstens fühle ich mich mit meinen 89 noch nicht alt, kann noch gut allein für mich sorgen und wenn das nicht mehr geht, sollen sich Profis um mich kümmern.“ 

Sie plauderten noch, als sie am Ende ihres Spaziergangs vor dem Café Heimatblick ankamen und sie ihn daran erinnerte, dass er ihr doch einen Kaffee schuldete. „Mit dem größten Vergnügen und ein Stück Kuchen dazu.“ Sie fanden einen Tisch am Fenster des Cafés, so dass sie sich weiter unterhalten und dabei aus dem Fenster sehen konnten. Allerdings ertappte sie sich immer wieder dabei, dass sie ihren neuen Nachbarn anschaute. Sie sah die vielen Lachfalten an seinen Augen und seine braunen Augen waren fast so dunkel wie die von ihrem Heinz. Er hingegen lächelte sie immer mal wieder an, wenn er sprach, und seine Geschichten hatten auch oft etwas lustiges, Aus seinen Erzählungen hörte sie seinen feinen Humor und seine positive Lebenseinstellung heraus und das gefiel ihr sehr. 

Am nächsten Tag sprach sie mit der Heimleitung und arrangierte, dass er mittags mit ihr an einem Tisch sitzen konnte. Sie bekam auch wieder ein Platz am Fenster, wo die Sonne sie beide in schönes Licht tauchen würde. Schon sehr früh ging sie zum Essen und erwartete ihn hoffnungsvoll. Gegen 12:30 Uhr kam er herein, sah sich suchend um und entdeckte sie im Sonnenschein. Er kam zu ihr an den Tisch und fragte höflich: „Darf ich bei ihnen sitzen?“ „Ja sicher, schauen sie mal, ihr Tischkärtchen ist schon da.“ Jetzt errötete er leicht und setzte sich mit einem Räuspern zu ihr. 

Nach dem Essen unternahmen sie wieder einen Spaziergang und diesmal hakte sie sich bei ihm ein. Sie plauderten die ganze Zeit und merkten gar nicht wie die Zeit verging. Als sie am späten Nachmittag zum Heim zurückkamen, fragte sie ihn: „Sie haben doch sicher noch nicht viel eingekauft, wollen sie bei mir zu Abend essen?“ „Sehr gern. Und sie haben recht, viel habe ich noch nicht. Das muss ich morgen mal nachholen. Aber eine Flasche Wein kann ich mitbringen. Mögen sie Rioja?“ „Ja, der erinnert mich an viele Urlaube in Andalusien.“ 

So hatten sie auch gleich ein weiteres Thema gefunden, denn auch er war viel gereist und der letzte Urlaub mit seiner Elfriede hatte sie nach Sevilla und Cordoba geführt. Er hatte ein paar Bilder mitgebracht, die er ihr freudstrahlend zeigte. Sie holte eins ihrer Alben aus dem Regal und dann schauten sie zusammen die Fotos an, die ihr Heinz geschossen hatte, der begeisterter Hobbyfotograf gewesen war. 

Beim zweiten Glas Wein sagte er zu ihr: „Ich finde, wo wir doch so nah beieinander wohnen, könnten wir uns auch duzen. Ich denke als ältere von uns beiden darf ich das vorschlagen.“ „Sehr gern, ich hab mich das nicht getraut.“ Der kurze Kuss nach dem Arm in Arm zuprosten gefiel beiden und so wurde es noch ein längerer Abend. Die Flasche Rioja war schon längst leer als er sich verabschiedete und zu ihr sagte: „Wenn du mir eine Chance gibst, morgen früh etwas einzukaufen, kannst du gern um neun zu mir zum Frühstück kommen. Magst du Rührei?“

Das war der Beginn von vielen Tagen, die sie gemeinsam verbrachten. Ihre Mahlzeiten nahmen sie jetzt fast immer gemeinsam ein, mittags meist im Speisesaal und morgens und abends mal bei ihr, mal bei ihm. Eines freitagabends, als sie bei ihm wieder einmal lange geplaudert hatten, fragte er sie, als sie in ihr Apartment gehen wollte: „Möchtest du heute Nacht nicht hierbleiben?“ „Das möchte ich gern, aber lass mich erst noch mal schnell nach Hause gehen und ein paar Sachen holen. Du weißt doch, Frauen brauchen da ein paar Utensilien mehr als Männer.“ „Es ist schön, wie du sagst, nach Hause gehen und ich wünsche mir, dass wir unser Zuhause hier im Heim noch lange genießen können. Beeil dich und lass mich nicht zu lange warten.“`

const chapterThree = `Sie war dreizehn Jahre alt und das, was man zu ihrer Zeit eine graue Maus nannte. Die meisten anderen Mädchen in der Klasse schienen für die Buben wesentlich interessanter zu sein. Sie tat sich eher mit anderen Klassenkameradinnen zusammen, die ebenfalls nicht in den Mittelpunkt passten. Sie fand es immer unfair, wenn jemand wegen seines Aussehens oder wegen seiner Kleidung herablassend behandelt oder belächelt wurde. Es machte ihr nichts aus, dass sie deshalb auch den Anschluss zu den beliebteren Mädchen nie wirklich fand. 

In diesem Schuljahr hatte der Klassenvorstand mit einigen interessierten Schülern und Schülerinnen eine Hobbygruppe gegründet, die sich in der Freizeit zum Wandern traf. In diesem völlig anderen Rahmen freundete sie sich mit Maria an. Sie erkannten, dass sie ähnliche Interessen hatten und dass sie gut miteinander reden konnten. So kam es, dass sie kurz vor den Sommerferien zum ersten Mal bei der neuen Freundin zu Hause eingeladen war. Sie wohnte in einer angenehm großen Wohnung, zusammen mit ihren Eltern und den vier Geschwistern. Da war immer etwas los und Marias Eltern sahen es gern, wenn sie zu Besuch kam. Sie war ein ruhiges, höfliches, unaufdringliches Kind, das sie bald liebgewonnen hatten. Sie wirkte einerseits tatsächlich noch sehr kindlich, wahrscheinlich durch ihr unscheinbares Aussehen, und ihre angepasste Art. Andererseits war ihrem Körper bereits die erste Veränderung in Richtung Weiblichkeit anzusehen und man konnte erahnen, dass die kindlichen Züge bald ganz verschwunden sein würden. Jedenfalls fand sie durch Maria doch den Anschluss zu der Clique, die sich regelmäßig traf, um im Hof Völkerball oder im Aufenthaltsraum im Keller Tischtennis zu spielen. Mindestens einmal die Woche verbrachte sie den Nachmittag dort und manchmal durfte sie bei Maria übernachten. 

Zu Beginn der Ferien feierten sie Marias Geburtstag. Marias große Familie und alle ihre Freundinnen sowie ein paar Jugendliche aus der Nachbarschaft waren dabei. Es gab großes Programm. Die Geburtstagsjause mit der überirdisch großen Marzipantorte, Wettspiele, bei denen man Preise gewinnen konnte und ganz viel laute Musik. Zum Abschluss ging man wieder in den Keller zum Ping-Pong Spiel. Und da passierte es. Plötzlich war er da. Er lehnte ganz lässig an der Wand und beobachtete das Geschehen. Dunkle Haare und noch dunklere Augen ließen ihn unheimlich interessant wirken. Er wohnte ein paar Stiegen weiter, wie Maria ihr später sagte. „Gefällt er dir?“, wollte sie gleich wissen. „Ach, ich weiß nicht. Er hat ein paarmal so lieb zu mir herübergeschaut. Das ist mir halt aufgefallen.“, antwortete sie verlegen und wurde dabei knallrot im Gesicht. Maria wusste auch, dass er schon achtzehn war und dass er eine Tischlerlehre besuchte. Und er hatte keine Freundin. 

Sie war so schrecklich schüchtern. Er war von da an fast jedes Mal da, wenn sie Ping-Pong spielten. Ihr Herz schlug höher, ganz besonders als er sie fragte, ob sie auch mit ihm eine Partie spielen wolle. Sie hatten so viel Spaß zusammen und sie vergaß oft, dass sie nicht allein waren. Oft hatte sie sich vorgestellt, wie es wohl wäre, wenn sie sich zufällig hier wirklich allein treffen würden. Sie würden miteinander reden und vielleicht ohne Zuschauer Tischtennis spielen. Einmal träumte sie sogar von ihm. Sie waren allein in dem Kellerraum und er nahm ganz sacht ihre Hand in seine und küsste sie vorsichtig auf den Mund. Ganz aufgeregt wachte sie auf und wusste gar nicht, was mit ihr los war. 

Einige Wochen später, die Sommerferien waren fast zu Ende, war sie wieder bei ihrer Freundin. Der schöne Nachmittag war zu Ende und sie verabschiedete sich von der Familie, um nach Hause zu gehen. Da fiel ihr plötzlich ein, dass sie ihre Weste im Keller vergessen hatte. Sie borgte sich den Schlüssel und ging eilig hinunter in den leeren Raum. Allein fühlte sie sich da gar nicht wohl. Noch dazu war gar nicht abgeschlossen. Warum? Maria hatte doch vor ihren Augen zugesperrt. Da hörte sie plötzlich ein Geräusch und erschrak, als sie vor ihm stand. „Was machst du denn da?“, stieß sie hervor. Er antwortete genauso hastig: „Äh, ich hab meinen Schläger vergessen. Und du?“ „Hm, meine Weste. Ich hab meine Weste vergessen…“ Mehr konnte sie nicht sagen. Es wurde ihr heiß und sie wusste so gar nichts mit der Situation anzufangen. So sehr hatte sie diese Situation herbeigesehnt, und jetzt kann sie nur hervorstottern ‚Ich habe meine Weste vergessen‘. Ihm war die Lage, in der er hier war, scheinbar auch unangenehm. Er versuchte, locker zu wirken und meinte noch, dass es heute ein schöner Nachmittag war und dass er sich schon auf das Nächste Mal freut. 

Auf dem Heimweg war sie sehr verwirrt. Tausend Dinge gingen ihr durch den Kopf. Letztendlich war sie froh, dass er sie nicht geküsst hatte. Sie wollte doch noch etwas darauf warten.`

function wrapCol(elem){
    return <Row><Col>{elem}</Col></Row>;
}

function linesToRow(lines){
    return lines.split("\n").map((line) => wrapCol(line))
}

export default function FroschRows() {

    const data = [
        wrapCol(<h1>König Frosch</h1>),
        wrapCol(<h3>Gut Ding braucht Weile</h3>),
        linesToRow(chapterOne), 
        wrapCol(<h4>Späte Liebe</h4>),       
        linesToRow(chapterTwo),
        wrapCol(<h4>Ping Pong</h4>),       
        linesToRow(chapterThree),
        wrapCol(<Button variant="dark" block><a href="/kaufen" className="White-Link">Direkt beim Autor kaufen</a></Button>),
        wrapCol(<Button variant="dark" block><a href="https://www.thalia.de/shop/home/suggestartikel/A1063988208?sq=König%20Frosch&stype=productName" target="_blank" rel="noopener noreferrer" className="White-Link">Bei Thalia kaufen</a></Button>)      
    ];
    const divText = data.map((line) => <p>{line}</p>);

    return (
        <div class="text-justify">{divText}</div> 
    ); 

};