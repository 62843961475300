import React from "react";
import { Route, Switch } from 'react-router-dom'
import Container from 'react-bootstrap/Container';

import AnnaRows from './Anna/AnnaBuilder'
import ChuiHatariRows from './ChuiHatari/ChuiHatariBuilder'
import FroschRows from './Frosch/FroschBuilder'
import HopOffRows from './HopOff/HopOffBuilder'
import QuadrigaRows from './Quadriga/QuadrigaBuilder'
import ReiseRows from './Reise/ReiseBuilder'
import WoerterliebeRows from './Woerterliebe/WoerterliebeBuilder';

export default function BookPreview() {
    return (
        <div>
            <Container>    
                <Switch>
                    <Route path="/anna" component={AnnaRows} />
                    <Route path="/chui-hatari" component={ChuiHatariRows} />
                    <Route path="/frosch" component={FroschRows} />
                    <Route path="/hop-off" component={HopOffRows} />
                    <Route path="/quadriga" component={QuadrigaRows} />
                    <Route path="/reise" component={ReiseRows} />
                    <Route path="/woerterliebe" component={WoerterliebeRows} />
                </Switch>
            </Container>
        </div>
    );
  }