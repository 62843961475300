import React from 'react';
import { render } from 'react-dom';
import App from './components/App/App';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import ReactGA from 'react-ga';
// Cookie “_ga” will be soon rejected - same for _gid & _gat
// GA is disabled on many browsers if they have Cookies disabled by default 
ReactGA.initialize('UA-196679933-1');

render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.querySelector('#root')
);

