import React from "react";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import './../index.css';

export function CommonNavBar() {
    return (
 
        <Navbar className="bg-dark" sticky="top" expand="sm">
                <Nav.Link className="White-Link" href="./">Startseite</Nav.Link>
                <Nav.Link className="White-Link" href="./autor">Autor</Nav.Link>
                <Nav.Link className="White-Link" href="./buecher">Bücher</Nav.Link>
                <Nav.Link className="White-Link" href="./events">Events</Nav.Link>
                <Nav.Link className="White-Link" href="./kaufen">Kaufen</Nav.Link>
                <Nav.Link className="White-Link" href="./kontakt">Kontakt</Nav.Link>
        </Navbar>
    ); 
};
