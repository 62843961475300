import React from "react";
import { Row, Col, Button } from 'react-bootstrap';

const chapterThree = `Viktor steht erst ein paar Minuten in der Lobby, als Annemarie erscheint. Er ist entzückt von ihrem Anblick. Am Nachmittag im Bus war sie in engen Jeans und einem neckischen rosa Shirt, das eine Schulter unbedeckt ließ, unterwegs gewesen. Das sah schon sehr aufreizend aus. Aber jetzt raubt sie ihm für einen Moment den Atem. Ihre schlanke Figur wird in dem enganliegenden, dunkelgrünen Cocktailkleid sehr betont. Das kurze, blonde Haar ist elegant glatt gestylt. Ein paar Fransen fallen in ihre Stirn und unterstreichen das dezent geschminkte, freundliche Gesicht. Ihre Augen faszinieren ihn. Er schaut den Frauen immer zuerst in die Augen. Und manchmal sieht er darin etwas blitzen, das ihn magisch anzieht. Dann kann er einfach nicht anders, als sich intensiv um sie zu bemühen.

„Hallo, schöne Frau!“ begrüßt er sie leise, nimmt andächtig ihre rechte Hand in seine und haucht charmant einen Kuss auf ihren Handrücken. „Du siehst bezaubernd aus. Da habe ich doch für uns das richtige Lokal ausgesucht.“ Neugierig geworden, hakt sie sofort nach: „So? Wohin willst du mich denn entführen?“ „Ich hab für uns in einem Restaurant am Parkring reserviert. Da wirst staunen.“ Viktor ist eigentlich als Busfahrer kein Großverdiener, aber wenn ihm etwas wichtig ist, dann lässt er es sich auch was kosten. Da geht halt auch etwas vom Trinkgeld aus dem Bus drauf. Und so scheint ihm für diesen ersten gemeinsamen Abend Das Schick gerade fein genug. Er war noch nicht oft hier, aber es reicht, um sich ein wenig auszukennen und die Dame erkennen zu lassen, dass ihm das Lokal nicht fremd ist. Sie ist offensichtlich beeindruckt: „Wahnsinn, dieses Ambiente hier! Schau dir die herrliche Aussicht an! Man kann fast ganz Wien sehen!“ 

Die beiden genießen ein köstliches Vier-Gänge-Menü mit Weinbegleitung und tasten sich im Gespräch vorsichtig aneinander heran. „Warum bist du mit deinen Freundinnen hier?“, will Viktor wissen, „Es gibt doch zu Hause sicher jemand, der auf dich wartet, oder?“ Sie lächelt etwas verlegen und antwortet: „Zu Hause in Rosenheim gibt es meine beiden Kinder, die sich freuen, wenn ich gut wieder heimkomme. Sie sind aber schon erwachsen und wohnen nicht mehr bei mir. Mann hab ich keinen, wenn du das meinst. Wir sind schon ewig geschieden. …lange Geschichte… Und du? Hast du niemand? Ein Mannsbild, das aussieht wie du… kann man sich gar nicht vorstellen.“ Ein verlegenes Hüsteln folgt und Viktor senkt verdächtig seinen Blick, als er zugibt: „Ähm … naja … weißt du … wenn ich ehrlich bin …“. „Ich bitte darum!“, unterbricht sie ihn. „Also wenn ich ehrlich bin, ich bin verheiratet.“ Annemaries Blick hat sofort etwas Trauriges. Das strahlende Lächeln friert förmlich ein. „Aber mit meiner Ehe ist das so eine Sache“, fährt er schnell fort, „wir leben schon lang nur mehr nebeneinanderher. Sex gibt’s scho ewig nimmer. Kannst du dir vorstellen, wie lange ein Mann das aushält?“ Ihr Gesichtsausdruck entspannt sich ein wenig. Sie berührt seine Hand und meint: „Das kann ich mir gut vorstellen. Aber wie arrangiert ihr euch? Weiß sie von unserem Date?“ Wieder ein Räuspern: „Hm, nein. Sie denkt, ich hab eine dienstliche Besprechung…“ 

Viktor ist nicht immer so offen und ehrlich bei den Frauen, aber irgendwie hat es ihm Annemarie mit ihrem fröhlichen, freundlichen Wesen sehr angetan. Er findet sie extrem anziehend und lässt daher nicht locker: „Annemarie, wie wär’s, gehen wir noch wo anders was trinken? Dann zeig ich dir auch noch ein paar ‚Sehenswürdigkeiten‘, wenn du willst.“ Dabei lächelt er spitzbübisch.`

const chapterFour = `Josefine Mirbach hat sich vorgenommen, heute eine Stadtrundfahrt zu machen. Gestern hat sie online ein Ticket für heute 10:30 h gebucht. Als sie vom Frühstück zurückkommt, zieht sie sich um und spaziert zur Oper, wo die hop on – hop off Busse abfahren. Obwohl die Schweizerin leger gekleidet ist, sie trägt eine weiße Hose und eine bunt bedruckte Bluse, fällt sie unter den anderen Wartenden auf wie die Bienenkönigin zwischen den Arbeiterinnen. Das mag an der kessen Frisur liegen, dem hellblonden Schopf, der nicht mit dem Kamm, sondern nur von den mit Gel benetzen Fingern gebändigt wurde. Oder an den knallroten Fingernägeln und die ebenso intensivroten Lippen? Es ist ihr jedenfalls anzumerken, dass es sie nicht im Geringsten stört, wenn der eine oder andere Mann hinter dem Rücken seiner Frau den Kopf nach ihr verdreht. Sie muss ein wenig warten, wie viele andere Touristen auch, denn der Busfahrer ist noch nicht da. 

Als er erscheint, nimmt er die Tickets der Gäste in Empfang und hat für jeden ein paar freundliche Worte. Als die Reihe an Josi kommt, fällt sein Blick auf ihr Dekolleté, worauf er sie spitzbübisch anlächelt und sagt: „Grüß Gott gnä‘ Frau. Herzlich willkommen in meinem Bus. Setzen sie sich doch nach vorn, dann kann ich ihnen während der Fahrt noch etwas mehr vom schönen Wien erzählen.“ Als er ihr einen Kopfhörer reicht, streicht seine Hand wie zufällig über ihren Arm. Ein leichter Schauer läuft ihr über den Rücken und sie schaut den Mann genauer an. Er sieht sehr gut aus, ist 45 – 50 Jahre alt, trägt ein weißes Hemd mit Krawatte, eine schwarze Hose und eine Sonnenbrille, die ihn sehr interessant wirken lässt.  Die protzige Uhr am Handgelenk und die dicke Goldkette am anderen Arm stören sie ein wenig. Aber sie geht weiter und setzt sich in die zweite Reihe, und zwar so, dass sie ihn gut im Auge behalten kann. 

Während der Fahrt ist der Fahrer zunächst noch ruhig und sie lauscht den Ansagen in ihrem Kopfhörer. Der Blickwechsel im Rückspiegel lässt jedoch keinen Zweifel darüber, dass sich hier etwas anbahnt. Erst beim Schloss Schönbrunn spricht er sie wieder an und sie nützen die kurze Pause in der Station für ein Gespräch. Josefine ist ganz baff, als er sie mit einer Fürstin im Schloss vergleicht.  Der Charmeur weckt ihr Interesse und als er sie zum Kaffee einlädt, sagt sie spontan zu und freut sich schon darauf.`

const chapterFive = `Viktor betritt das Hotel Bristol, schaut sich um und entdeckt Josi im Café. Sie diskutiert mit dem Kellner. Als er zu ihr kommt, hört er, wie sie zu ihm sagt: „Ich habe jetzt lange genug auf den Kaffee gewartet. Wir werden unsere Besprechung jetzt auf meinem Zimmer fortsetzen. Lassen sie die Getränke bitte umgehend auf Zimmer 369 bringen.“ Dann wendet sie sich an Viktor: „Der Service hier ist heute nicht sehr effizient. Ich hoffe, es ist in Ordnung, wenn wir unseren Kaffee in meinem Zimmer nehmen.“ „Natürlich Fürstin.“ Sie lächelt zufrieden. Die beiden gehen zum Aufzug und fahren in den dritten Stock.

Im Zimmer unterhalten die beiden sich prächtig. Dann kommt der Kaffee und sie unterbrechen ihren Small Talk kurz, bis Josi den Kellner wieder verabschiedet hat. Beim Kaffeetrinken fragt Viktor: „Sag, Durchlaucht, gibt es noch einen anderen Grund, warum wir den Kaffee hier im Zimmer einnehmen?“ „Ja, ich dachte hier sind wir ungestört.“ Viktor erwidert ihren lüsternen Blick mit einem Augenzwinkern: „Das habe ich verstanden“. Er stellt die Kaffeetasse auf den Tisch, nimmt ihre rechte Hand in seine, haucht einen raffiniert feinen Kuss drauf und fragt leise: „Verehrte Fürstin, wollen wir unser Sightseeing auf einer anderen Ebene fortsetzen?“ Sein Blick wandert hinüber zu dem einladenden, riesigen Doppelbett und Josis Augen wandern mit. Das ist Aufforderung genug für sie, und bei diesem ersten Herantasten lässt sie Viktor erkennen, dass ihr an mehr gelegen ist als an langweiligem Blümchensex. 

Weil aber seine Zeit knapp ist, muss er sich nach einer Stunde wilder Zärtlichkeiten von ihr verabschieden, nicht ohne sich für den Abend zu verabreden.`


function wrapCol(elem){
    return <Row><Col>{elem}</Col></Row>;
}

function linesToRow(lines){
    return lines.split("\n").map((line) => wrapCol(line))
}

export default function HopOffRows() {

    const data = [
        wrapCol(<h1>Hop off - hop on</h1>),
        wrapCol(<p>Am 15. Juli 2019 erscheint folgende Meldung in der Kronen Zeitung:</p>), 
        wrapCol(<h4>Frau im Bristol tot aufgefunden</h4>),    
        
        <Row><Col>
            <b>Wien:</b> Gestern wurde eine 49-jährige Frau im Hotel Bristol im ersten Bezirk tot aufgefunden. Die Polizei geht von einem Gewaltverbrechen mit sexuellem Hintergrund aus.
            <br></br>Ein Zimmermädchen hatte die Frau tot in ihrem Bett im Hotel Bristol entdeckt. Die Frau war fast unbekleidet. Die genaue Todesursache steht noch nicht fest. Die Polizei vermutet ein Verbrechen oder eine sexuelle Begegnung mit tödlichem Ausgang. Es ist noch nicht klar, warum die Frau im Hotel war und wer sie begleitet hat. Die ersten Vernehmungen der Gäste und Hotelmitarbeiter durch die Polizei haben keine wirklichen Erkenntnisse gebracht. Über Näheres zu den Hintergründen werden wir in den nächsten Tagen berichten.
        </Col></Row>,

        wrapCol(<h4>14. Juli 8:30 h</h4>),       

        <Row><Col><em>"I hass des, am Sonntag in der Früh scho zu an Mord zu müssen. Dabei ist doch des noch gar net klar, ob es überhaupt a Mord is. Wenn i mir des so åschau, könnt des genauso guat a Unfall sei, wo zwa Leut a bissi härteren Sex hatten und dann is des aus'n Ruder glauf'n," </em> denkt Major Alois Schmalzl, als er sich im Zimmer 369 im Hotel Bristol näher umschaut. Schmalzl ist ein 58-jähriger, stämmiger Beamter der Kriminalpolizei Wien, 185 cm groß, mit leicht ergrautem Haar und einem stattlichen Schnurrbart. Mit einem grimmigen Gesichtsausdruck beugt er sich über die Leiche. Die Frau hat nur noch Reste eines roten Slips am Körper, ein Plastiksackerl über dem Kopf und ist am Bett festgebunden. „Womit ist sie da angebunden?“, fragt er seinen Kollegen Fritz Mosic. „Sieht so aus, als hätten sie oder hätte der Täter die Gürtel der weißen Hotelbademäntel dazu benutzt. Die hängen im Bad.“ Sie schauen sich noch ein wenig um, sehen zwei Tassen mit eingetrocknetem Kaffee auf dem Sideboard und auf dem Tisch eine Flasche Prosecco und zwei benutzte Gläser. „Schaut so aus, als hätten sie zuerst Kaffee getrunken und später Sekt“, sagt Fritz. „Wieso denkst du das?“ „Na, der Kaffee in den Tassen ist vollkommen angetrocknet, in den Sektgläsern ist noch Flüssigkeit.“
            <br></br>Auf alle Fälle muss die Spusi her, dann erfahren wir mehr. „Fritz, kannst du mit der Anhörung des Personals beginnen und auch die Gäste befragen? Ich schick dir noch Verstärkung, denn i muss noch zu an anderen Fall. Kannst du mit der Spusi besprechen, dass sie möglichst von allen im Hotel DNA-Proben nehmen!“ „Ach du lieber Gott, das wird die Direktion aber gar nicht freuen. Der Chef war eben schon ganz aufgeregt, als ich ihn in der Lobby getroffen habe.“ „Weißt du was Besseres? Das sieht doch nach einem Sexualdelikt aus. Also müssen wir das machen, bevor uns die Gäste wieder abreisen, oder?“ „Ja, hast ja recht, aber des wird a furchtbare Prozedur.“ 
            <br></br>Hauptmann Fritz Mosic ist mit seinen 39 Jahren bereits ein alter Hase bei der Kripo. Er ist 1,82 m groß, hat braunes, schon leicht ausgedünntes Haar und markante Gesichtszüge. Er wirkt sehr gepflegt und sportlich. Seine Lippen sind meist zu einem schelmischen Grinsen verzogen, was oft bei anderen den Eindruck erweckt, er sei fröhlich und sehr freundlich. Wenn er dann spricht, merkt man schnell, dass das oft nicht stimmt. Bei Befragungen von Verdächtigen kann er ziemlich hartnäckig sein.
            <br></br>Obwohl ihm der Kopf noch schwirrt von den Cocktails am Abend zuvor, beginnt er mit der Befragung der Mitarbeiter an der Rezeption. Er stellt fest, dass der Nachtportier nicht im Hause ist. Den müssen wir also später befragen. Der Barkeeper von gestern Abend hat natürlich jetzt auch frei. Also noch ein Kandidat für später. 
            <br></br>Kurz drauf kommen einige Kollegen ins Hotel und Fritz Mosic instruiert sie, was und wonach sie fragen und suchen sollen. Dann teilen sie sich auf und nehmen die Befragung des Personals und der Gäste in Angriff. „Um 12 treffen wir uns hier in der Lobby und tauschen unsere Ergebnisse aus“, verabreden die Beamten, bevor alle losziehen.
        </Col></Row>,

        wrapCol(<h4>6. Mai 18:00 h</h4>),
        linesToRow(chapterThree),

        wrapCol(<h4>13. Juli 9:30 h</h4>),
        linesToRow(chapterFour),

        wrapCol(<h4>13. Juli 12:20 h</h4>),
        linesToRow(chapterFive),

        wrapCol(<Button variant="dark" block><a href="/kaufen" className="White-Link">Taschenbuch direkt beim Autor kaufen</a></Button>),
        wrapCol(<Button variant="dark" block><a href="https://www.buchschmiede.at/app/book/138001-Jove-Viller-hop-off-hop-on-Ein-Krimi-aus-Wien;bookType=PB" target="_blank" rel="noopener noreferrer" className="White-Link">Taschenbuch direkt beim Verlag kaufen</a></Button>),
        wrapCol(<Button variant="dark" block><a href="https://www.thalia.de/shop/home/artikeldetails/A1071359963" target="_blank" rel="noopener noreferrer" className="White-Link">Taschenbuch oder eBook bei Thalia kaufen</a></Button>),
    ];
    const divText = data.map((line) => <p>{line}</p>);

    return (
        <div class="text-justify">{divText}</div> 
    ); 

};