import React from "react";

import { Row, Col, Button, Container } from 'react-bootstrap';

import './Books.css';

export default function Books() {
return (
    <div className="Books">

        <Container>

        <Row className="Books-row">
                <Col>
                    <img src={"/hop_off.jpg"} alt="Hopp Off" className="Books-image" />     
                </Col>
                <Col md={6}>
                    <div className="text-justify">
                    <em>hop off - hop on</em> ist ein Krimi, der in Wien spielt und in zwei Zeitebenen erzählt wird. Viktor, der Fahrer eines Stadtrundfahrtbusses in Wien, ist ein echter Hallodri. Immer wieder quatscht er Frauen an, die in seinem Bus mitfahren und überredet sie, mit ihm Kaffee zu trinken. Oft kommt es auch zu Zärtlichkeiten, denn er trifft sich meist mit ihnen in ihrem Hotel.
                    <br />
                    <br />
                    Eines Sonntags im Juli 2019 wird Josefine Mirbach, eine Schweizerin aus Zürich, in ihrem Zimmer im Hotel Bristol tot aufgefunden. Viktor, der am Tag zuvor mit ihr intim war, hat Angst, dass die Polizei ihm auf die Schliche kommen könnte. Als er in der Zeitung liest, dass die Polizei einen anderen Mann verdächtigt, ist er anfangs beruhigt. Es gibt aber noch weitere Verdächtige und Kommissar Schmalzl und seine SOKO Josi stehen zunächst vor einem Rätsel.
                    </div>
                </Col>

                <Col md={3}>
                    <Button variant="dark" block><a href="/hop-off" rel="noopener noreferrer" className="White-Link">Leseprobe</a></Button>
                    <Button variant="dark" block><a href="/kaufen" rel="noopener noreferrer" className="White-Link">Jetzt kaufen</a></Button>
                </Col>
            </Row>

            <Row className="Books-row">
                <Col>
                    <img src={"/reise.jpg"} alt="Reise unseres Lebens" className="Books-image" />     
                </Col>
                <Col md={6}>
                    <div className="text-justify">
                    In diesem Buch haben die beiden Autoren Eva Vieh und Hajo Müller alias Jove Viller ihre Safari in Ostafrika im Sommer 2023 in Worten und beeindruckenden Bildern dokumentiert. Es handelt sich nicht um einen chronologischen Bericht, sondern um die Erlebnisse und Begegnungen auf ihrer Reise. Die sehr nachhaltigen Eindrücke der beiden haben dazu geführt, dass hier sie großartige Fotos zeigen und auch interessante Stories erzählen. Beides vermittelt dem Leser einen Eindruck davon, was einem auf einer Safari in Afrika alles begegnen kann. Hajo war schon oft in Afrika, und mit dieser Reise ist das Afrika Virus auf Eva übergesprungen. Deshalb wollen die beiden auch eine weitere Reise unternehmen, wahrscheinlich 2025 mit dem Ziel südliches Afrika.
                    </div>
                </Col>

                <Col md={3}>
                    <Button variant="dark" block><a href="/reise" rel="noopener noreferrer" className="White-Link">Leseprobe</a></Button>
                    <Button variant="dark" block><a href="/kaufen" rel="noopener noreferrer" className="White-Link">Jetzt kaufen</a></Button>
                </Col>
            </Row>

            <Row className="Books-row">
                <Col>
                    <img src={"/woerterliebe.jpg"} alt="Woerterliebe" className="Books-image" />     
                </Col>
                <Col md={6}>
                    <div className="text-justify">
                        Max und Leni beginnen übers Internet, miteinander Wordox zu spielen. Auf spannende Spiele folgen aufregende, wie gefühlvolle Dialoge im Chat, bis die beiden es nicht mehr erwarten können, sich zu sehen. Sie lebt in Wien, er in Köln, daher treffen sie sich in der Mitte. Ihre erste Begegnung ist für beide ein weltveränderndes Erlebnis. Anschließend werden viele Städte Schauplatz ihrer Leidenschaft. Es wird mehr daraus, als die beiden dachten, obwohl Max verheiratet ist. Dies führt zu einem emotionalen Auf und Ab: Gewissensbisse und Verantwortungsgefühl bei ihm und die Unfähigkeit, zu vertrauen, ihrerseits, führen zu Gefühlsausbrüchen und dazu, dass es unsicher ist, was aus dieser Liebe werden kann. 
                    </div>
                </Col>

                <Col md={3}>
                    <Button variant="dark" block><a href="/woerterliebe" rel="noopener noreferrer" className="White-Link">Leseprobe</a></Button>
                    <Button variant="dark" block><a href="https://www.novumverlag.com/onlineshop/belletristik/erotik-sinnlichkeit/woerter-liebe.html?no_cache=1&tx_mdprodukte_pi1%5Bmode%5D=ranking&cHash=d82b2056c01cd5d8c4cd61c76a3e9a0d" target="_blank" rel="noopener noreferrer" className="White-Link">Bewertungen</a></Button>
                    <Button variant="dark" block><a href="/kaufen" rel="noopener noreferrer" className="White-Link">Jetzt kaufen</a></Button>
                </Col>
            </Row>

            <Row  className="Books-row">
                <Col md={3}>
                    <img src={"/quadriga_liebe.jpg"} alt="Quadriga Liebe" className="Books-image" />    
                </Col>
                <Col md={6}>
                    <div className="text-justify">
                        Diese Liebesgeschichte von vier jungen Menschen beschreibt, wie sich zwei Paare auf unterschiedlichen Wegen kennen und lieben lernen. Tina und Leo finden sich über die Dating Plattform Tinder, nachdem sie beide vorher bereits diverse Partner auf diesem Weg ausprobiert hatten. Lydia und Sven begegnen sich zufällig bei der Gamescom und sind gleich ganz hin und weg voneinander. Anschließend leben sie ihre Nord-, Südfernbeziehung. 
                        <br />
                        <br />
                        Die vier haben sehr verschiedene Hintergründe, Wohnorte und Erfahrungen und treffen später bei der Geburtstagsfeier von Marie in Münster aufeinander. Dabei kommt es zu Verwicklungen, weil das Geburtstagskind schon lange ein Auge auf Sven geworfen hat. Sie möchte ihn um jeden Preis für sich begeistern. Geht’s danach trotzdem mit der Quadriga weiter?  
                    </div>
                </Col>
                <Col md={3}>
                    <Button variant="dark" block><a href="/quadriga" rel="noopener noreferrer" className="White-Link">Leseprobe</a></Button>
                    <Button variant="dark" block><a href="https://www.novumverlag.com/onlineshop/buch/quadriga-liebe.html?no_cache=1&tx_mdprodukte_pi1%5Bmode%5D=ranking&cHash=701c825fe5a15fc11b17661549f61172" target="_blank" rel="noopener noreferrer" className="White-Link">Bewertungen</a></Button>
                    <Button variant="dark" block><a href="/kaufen" rel="noopener noreferrer" className="White-Link">Jetzt kaufen</a></Button>
                </Col>
            </Row>
         
            <Row  className="Books-row">
                <Col>
                    <img src={"/koenig_frosch.jpg"} alt="König Frosch" className="Books-image" />
                </Col>
                <Col md={6}>
                    <div className="text-justify">
                        In „König Frosch“ setzt Jove Viller sich mit dem Phänomen des Verliebens auseinander. In 50 spannenden und zum Teil lustigen, aber auch traurigen Kurzgeschichten werden Menschen in diversen Situationen beschrieben, wie sie miteinander in Kontakt kommen, wo es knistert und wo sich etwas entwickelt oder auch nicht. Dabei werden aktuelle Ereignisse mit solchen aus der Vergangenheit bunt gemischt und es gibt Erzählungen aus jeder Altersgruppe vom Teenager bis zum Senior. Immer stehen der Beginn und die Entwicklung einer Beziehung zwischen zwei Menschen im Vordergrund. Auf diese Weise erlebt der Leser ein Kaleidoskop über das unergründliche Wunder, wie, wo und wann Menschen zueinander finden.
                    </div>
                </Col>
                <Col md={3}>
                    <Button variant="dark" block><a href="/frosch" rel="noopener noreferrer" className="White-Link">Leseprobe</a></Button>
                    <Button variant="dark" block><a href="https://www.novumverlag.com/onlineshop/belletristik/sonstiges-allerlei/koenig-frosch.html" target="_blank" rel="noopener noreferrer" className="White-Link">Bewertungen</a></Button>
                    <Button variant="dark" block><a href="/kaufen" rel="noopener noreferrer" className="White-Link">Jetzt kaufen</a></Button>
                </Col>
            </Row>

            <Row  className="Books-row">
                <Col>
                    <img src={"/was_jetzt_anna.jpg"} alt="Anna" className="Books-image" />
                </Col>
                <Col md={6}>
                    <div className="text-justify">
                        Anna, ein 10-jähriges Mädchen, möchte unbedingt ein eigenes Haustier haben. Zum Geburtstag hofft sie, zwei Meerschweinchen zu bekommen. Das klappt leider aus Platzgründen nicht und sie übt mit dem Hamster Ihrer Freundin, wie es ist, Mutter eines Nagetiers zu sein. Dabei stellt sie fest, dass diese Tiere sehr nachtaktiv sind und ihr den Schlaf rauben. Nachdem sie mehrfach den Hund einer Freundin ausgeführt hat, träumt Anna davon, auch einen Hund ihr Eigen zu nennen. Aber auch das scheitert an äußeren Bedingungen. Im Urlaub auf einem Reiterhof entdeckt Anna ihre Liebe zu Pferden. Aber selbst ein Pony passt nicht in ihr Zimmer. Wie kann sie den Wunsch nach einem eigenen Pferd in die Tat umsetzen?     
                    </div>
                </Col>
                <Col md={3}>
                    <Button variant="dark" block><a href="/anna" rel="noopener noreferrer" className="White-Link">Leseprobe</a></Button>
                    <Button variant="dark" block><a href="https://www.amazon.de/product-reviews/B08VCN6BTJ/ref=acr_dp_hist_5?ie=UTF8&filterByStar=five_star&reviewerType=all_reviews#reviews-filter-bar" target="_blank" rel="noopener noreferrer" className="White-Link">Bewertungen</a></Button>
                    <Button variant="dark" block><a href="/kaufen" rel="noopener noreferrer" className="White-Link">Jetzt kaufen</a></Button>
                </Col>
            </Row>
                        
            <Row  className="Books-row">
                <Col>
                    <img src={"/chui_hatari.jpg"} alt="CHUI HATARI" className="Books-image" />
                </Col>
                <Col md={6}>
                    <div className="text-justify">
                        CHUI HATARI ist Suaheli und bedeutet „Gefährlicher Leopard.“ Dieses Buch enthält hervorragende Bilder vorn diversen Tieren in Afrika. Es erzählt die Geschichte vom kleinen Leopard Rudi, der mit seiner Schwester und seinen Eltern in der Savanne Afrikas lebt. In einer leichten Sprache für Kinder zwischen zwei und sechs Jahren werden die Abenteuer von Rudi beschrieben, als er von zu Hause ausreißt und vielen anderen Tieren begegnet. Am Abend findet Rudi nicht mehr allein den Weg nach Hause, bis sein Vater ihn wieder heimbringt.   
                    </div>
                </Col>
                <Col md={3}>
                    <Button variant="dark" block><a href="/chui-hatari" rel="noopener noreferrer" className="White-Link">Leseprobe</a></Button>
                    <Button variant="dark" block><a href="/kaufen" rel="noopener noreferrer" className="White-Link">Jetzt kaufen</a></Button>
                </Col>
            </Row>
                           
        </Container>
        
    </div>
    );
}