import React from "react";

import { Row, Col, Container } from 'react-bootstrap';

export default function Author() {
    return (
        <div className="Author">

            <Container>
                <Row>
                    <Col>
                        <h1 className="text-center">
                            Jove Viller 
                        </h1>
                        <h3 className="text-center">
                            (alias Eva Vieh & HAJO Müller)   
                        </h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="text-justify">
                            Am 20. Juni 2019 beschlossen Eva Vieh und HAJO Müller, die sich übers Spielen im Internet kennen und lieben gelernt haben, ihre Liebesgeschichte in Form eines autobiografischen Romans zu veröffentlichen. Aufgrund der Nähe zu realen Personen und Ereignissen traten sie zunächst nicht als Autoren in Erscheinung, sondern schufen die Kunstfigur JOVE VILLER. Unter diesem Namen wurde der Roman ‚Wörterliebe’ im Dezember 2020 veröffentlicht. Beflügelt durch dessen großen Erfolg entdeckten die beiden ihre Leidenschaft fürs Schreiben. Aufgrund einer Idee einer 10-jährigen Schülerin, die sich unbedingt ein Haustier wünschte, entstand in etwa zwei Wochen das Kinderbuch ‚Was jetzt, Anna?‘, in dem die Fragen und Wünsche beschrieben werden, mit denen sich viele Mädchen und Jungen im Alter zwischen 10 und 14 Jahren beschäftigen. Das Kinderbuch CHUI HATARI eignet sich hervorragend zum Vorlesen für zwei- bis sechsjährige Kinder, die gern etwas von wilden Tieren in Afrika kennenlernen wollen. Das Buch ist mit tollen Fotos bebildert und erzählt die Abenteuer von Rudi, einem kleinen Leoparden. Er büchst von zu Hause aus und findet nicht mehr Hause, bis sein Vater ihn wiederfindet.            
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="text-justify">
                        Es folgen weitere Bücher, deren Handlung nicht wie in ‚Wörterliebe‘ und ‚Was jetzt, Anna?‘ nur fiktioniert, sondern komplett fiktiv ist. So entstand der Roman ‚Quadriga-Liebe‘ und kurz darauf eine Sammlung von Kurzgeschichten unter dem Titel ‚König Frosch‘. In beiden Werken, ebenfalls unter dem Pseudonym JOVE VILLER verfasst, stehen erneut die Themen des sich Verliebens und Zueinanderfindens im Vordergrund. Das Besondere: das österreichisch-deutsche Autoren-Duo unter dem Pseudonym JOVE VILLER bleibt seinem Ansatz treu und sie entwickeln ihre Geschichten gemeinsam, schreiben aber abwechselnd. Damit bieten sie Ihren Leserinnen und Lesern unterschiedliche Perspektiven auf die Ereignisse an und können die Emotionen der Protagonisten jeweils aus weiblicher und männlicher Sicht transportieren. Das Buch „Reise unseres Lebens“ enthält kurze Geschichten und beeindruckende Bilder einer Ostafrikatour des Autors im Sommer 2023. Im Krimi aus Wien unter dem Titel ‚hop off -hop on‘, stehen auch wieder die zwischenmenschlichen Beziehungen im Vordergrund keine blutrünstigen Szenen.
                        </p>
                    </Col>
                </Row>
                <Row className="text-center">
                    <img  src="/eva_hajo_horizontal.png" alt="Author" className="full"/> 
                </Row>
            </Container>

        </div>
        );
}