import React from "react";
import emailjs from 'emailjs-com';
import { Button } from 'react-bootstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';

// TODO: change mail to jove.viller@gmx.net

export default function Contact() {

    const [formStatus, setFormStatus] = React.useState('Send')
    emailjs.init('I_03QWZZAHAJ1GPbZ')

    const changeButtonColor = (event) => {
        event.target.style.backgroundColor = "green"
     }

    const onSubmit = (event) => {
        event.preventDefault();
        const form = event.target;

        const { name, email, message } = event.target.elements
        const conForm = {
            name: name.value,
            email: email.value,
            message: message.value,
        }
        console.log("Received the following values:", conForm);

        emailjs.sendForm("service_pxzkf3x", "template_3a8vjqm", form).then(
            (response) => {
                console.log("SUCCESS!", response.status, response.text);
                setFormStatus("Verschickt!")
            },
            (error) => {
                console.log("FAILED...", error);
            },
        );
    };

    return (
        <div className="container mt-5">
            <h1>Kontakt</h1>
            <p>
                Über ein feedback oder Deine Fragen freuen wir uns.
            </p>
            <form onSubmit={onSubmit} id="form">
                <div className="mb-3">
                    <label className="form-label" htmlFor="name">
                        Name
                    </label>
                    <input className="form-control" type="text" id="name" name="name" required />
                </div>
                <div className="mb-3">
                    <label className="form-label" htmlFor="email">
                        E-Mail
                    </label>
                    <input className="form-control" type="email" id="email" name="email" required />
                </div>
                <div className="mb-3">
                    <label className="form-label" htmlFor="message">
                        Message
                    </label>
                    <textarea className="form-control" id="message" name="message" required />
                </div>
                <Button variant="dark" type="submit" onClick={changeButtonColor.bind(this)}>
                    {formStatus}
                </Button>
            </form>
        </div>
    )
}