import React from "react";
import { Row, Col, Button } from 'react-bootstrap';
import './ChuiHatari.css';

function wrapCol(elem){
    return <Row><Col>{elem}</Col></Row>;
}

export default function ChuiHatariRows() {

    const data = [
        wrapCol(<h1>CHUI HATARI</h1>),
        wrapCol(<h2>Die Abenteuer des kleinen Leoparden Rudi</h2>),
        wrapCol(<h4>Diese Geschichte spielt in Afrika und erzählt von Rudi und Lisa, zwei kleinen Leoparden und ihren Eltern Bastian und Mary.</h4>),       
        wrapCol(<img src={"/chui_hatari_1_leopards.jpg"} alt="Leopardenfamilie" className="Chui-image"/>),       
        wrapCol(<h4>Die Leopardenfamilie lebt in der Nähe von Leopoldsdorf, einem kleinen Ort in der trockenen Savanne Afrikas. Oft regnet es das ganze Jahr über nicht.</h4>),       
        wrapCol(<img src={"/chui_hatari_2_landscape.jpg"} alt="Landschaft" className="Chui-image"/>),
        wrapCol(<h4>Frühmorgens geht Papa Bastian auf die Jagd, damit die Familie etwas zu essen bekommt. Lisa und Rudi bleiben mit Mama Mary zu Hause.</h4>),       
        wrapCol(<img src={"/chui_hatari_3_leopard.jpg"} alt="Leopard" className="Chui-image"/>),
        wrapCol(<Button variant="dark" block><a href="/kaufen" className="White-Link">Direkt beim Autor kaufen</a></Button>),
        wrapCol(<Button variant="dark" block><a href="https://www.buchschmiede.at/app/book/169774-Jove-Viller-CHUI-HATARI;bookType=PB" target="_blank" rel="noopener noreferrer" className="White-Link">Direkt beim Verlag kaufen</a></Button>) 
    ];
    const divText = data.map((line) => <p>{line}</p>);

    return (
        <div class="text-justify">{divText}</div> 
    ); 

};