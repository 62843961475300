import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Helmet } from 'react-helmet';

import ReactGA from 'react-ga';

import { SocialIcon } from 'react-social-icons';

import { CommonNavBar } from '../../common/Navbar';
import { CommonCookieConsent } from '../../common/CookieConsent';

import Home from '../Home/Home'
import Author from '../Author/Author'
import Books from '../Books/Books'
import BuyBooks from '../BuyBooks/BuyBooks'
import Events from '../Events/Events'
import Contact from '../Contact/Contact'
import Impressum from '../Impressum/Impressum'
import BookPreview from '../Books/BookPreview'

import './../../index.css';

const App = () => {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  return <div>
      <Helmet>
        <title>Jove Viller</title>
      </Helmet>

      <CommonNavBar />
      
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/anna" component={BookPreview} />
        <Route path="/autor" component={Author} />
        <Route path="/buecher" component={Books} />
        <Route path="/chui-hatari" component={BookPreview} />
        <Route path="/events" component={Events} />
        <Route path="/frosch" component={BookPreview} />
        <Route path="/hatari" component={BookPreview} />
        <Route path="/hop-off" component={BookPreview} />
        <Route path="/impressum" component={Impressum} />
        <Route path="/kaufen" component={BuyBooks} />
        <Route path="/kontakt" component={Contact}/>
        <Route path="/quadriga" component={BookPreview} />
        <Route path="/reise" component={BookPreview} />
        <Route path="/woerterliebe" component={BookPreview} />
      </Switch>

      <CommonCookieConsent />

      <div className="small-margin"></div>

      <footer className='footer mt-auto py-3 bg-dark text-white'>
        <div className="container">
          <a className="White-Link smaller-input" href="impressum">Impressum</a>
          &nbsp;&nbsp;&nbsp;
          <SocialIcon style={{ height: 25, width: 25 }} url="https://www.facebook.com/joveviller/" />
          &nbsp;&nbsp;&nbsp;
          <SocialIcon style={{ height: 25, width: 25 }} url="https://www.instagram.com/jove.viller.autor/" />
        </div>
      </footer>
    </div>
}

export default App;