import React from "react";
import ReactPlayer from "react-player";
import { Row, Container, Col, Button } from 'react-bootstrap';
import './Events.css';
import './../Books/Books.css';

function singleEvent(event){

    var data = [
        <div><strong>{event["header"]}</strong></div>,
        event["text"]
    ]
    if (event["imageName"] != null) {
        data.push(<Row><img src={(event["imageName"])} alt="" className="Event-image" /></Row>)
    }
    if (event["multipleImages"] != null) {
        let i = 0;

        let images = []
        while (i < event["multipleImages"].length) {
            images.push(<Col><img src={(event["multipleImages"][i])} alt="" className="Multiple-image" /></Col>)
            if (i % 2 === 1) {
                data.push(<Row>{images}</Row>)
                images = []
            }
            i++;
        }
        // shows the remaining image with 50% size
        if (images.length > 0) {
            data.push(<Row><div className="Event-image">{images}</div></Row>)
        }
    }
    if (event["event"] != null) {
        data.push(<Row><Button variant="dark" block><a href={event["event"]["link"]} target="_blank" rel="noopener noreferrer" className="White-Link">{event["event"]["text"]}</a></Button></Row>)
    }
    if (event["zoom"] != null) {
        data.push(" Die Veranstaltung erfolgt via Zoom:")
        data.push(<Button variant="dark" block><a href={event["zoom"]["link"]} target="_blank" rel="noopener noreferrer" className="White-Link">Zoom Link (Meeting-ID: {event["zoom"]["meetingId"]} & Kenncode: {event["zoom"]["code"]})</a></Button>)
    }
    if (event["recordingLink"] != null) {
        data.push(" Hier kannst du eine Aufnahme der Lesung ansehen: ")
        data.push(<ReactPlayer url={event["recordingLink"]}/>);
    }   
    return [<Row className="Books-row"><Col>{data}</Col></Row>,<p></p>];
}

function AllEvents() {

    let eventsConfig = require('./events.json');
    const data = [];

    eventsConfig.forEach(function (event, index, arr){
        data.push(singleEvent(event));
    });

    return (
        <div className="text-justify">{data}</div> 
    ); 

};


export default function Events() {
    return (
        <div className="Events">

            <Container  fluid="md">    

                <AllEvents />
                                   
            </Container> 
        </div>
        );
}
