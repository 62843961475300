import React from "react";
import emailjs from 'emailjs-com';
import { Button } from 'react-bootstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';

// TODO: change mail to jove.viller@gmx.net
// TODO: create separate sections for address to make it simpler to fill in
// TODO: add to buy section of all books

export default function BuyBooks() {

    const [formStatus, setFormStatus] = React.useState('Absenden')
    emailjs.init('I_03QWZZAHAJ1GPbZ')

    const changeButtonColor = (event) => {
        event.target.style.backgroundColor = "green"
     }

    const onSubmit = (event) => {
        event.preventDefault();
        const form = event.target;

        const { name, phone, email, signature, address } = event.target.elements;
        const books = Array.from(form.querySelectorAll('input[name="books"]:checked')).map(checkbox => checkbox.value);

        const conForm = {
            name: name.value,
            phone: phone.value,
            email: email.value,
            signature: signature.value,
            address: address.value,
            books: books,
        }
        console.log("Received the following values:", conForm);

        emailjs.sendForm("service_pxzkf3x", "template_wgxlizf", form).then(
            (response) => {
                console.log("SUCCESS!", response.status, response.text);
                setFormStatus("Bestellt!")
                
            },
            (error) => {
                console.log("FAILED...", error);
            },
        );
    };

    return (
        <div className="container mt-5">
            <h1>Bücherbestellung</h1>
            <p>
                Bestellung direkt vom Autor Jove Viller mit Signatur.
            </p>
            <form onSubmit={onSubmit} id="form">
                <div className="mb-3">
                    <label className="form-label" htmlFor="name">
                        Name
                    </label>
                    <input className="form-control" type="text" id="name" name="name" required />
                </div>
                <div className="mb-3">
                    <label className="form-label" htmlFor="phone">
                        Meine Telefonnummer für evtl. Rückfragen:
                    </label>
                    <input className="form-control" type="tel" id="phone" name="phone" required />
                </div>
                <div className="mb-3">
                    <label className="form-label" htmlFor="email">
                        Meine E-Mail für evtl. Rückfragen:
                    </label>
                    <input className="form-control" type="email" id="email" name="email" required />
                </div>
                <div className="mb-3">
                    <legend>Ich möchte folgende Bücher bestellen:</legend>
                    <div>
                        <input type="checkbox" name="books" value="Reise unseres Lebens"/><label htmlFor="reise" style={{ marginLeft: '5px' }}               > Reise unseres Lebens   - Preis 28 € inkl. Versandkosten</label>
                    </div>
                    <div>
                        <input type="checkbox" name="books" value="hop off - hop on"/><label htmlFor="hop-off" style={{ marginLeft: '5px' }}           > hop off - hop on       - Preis 17 € inkl. Versandkosten</label>
                    </div>
                    <div>
                        <input type="checkbox" name="books" value="König Frosch"/><label htmlFor="koenig" style={{ marginLeft: '5px' }}             > König Frosch           - Preis 19 € inkl. Versandkosten</label>
                    </div>
                    <div>
                        <input type="checkbox" name="books" value="Quadriga Liebe"/><label htmlFor="quadriga" style={{ marginLeft: '5px' }}         > Quadriga Liebe         - Preis 20 € inkl. Versandkosten</label>
                    </div>
                    <div>
                        <input type="checkbox" name="books" value="Wörterliebe"/><label htmlFor="woerterliebe" style={{ marginLeft: '5px' }} > Wörterliebe            - Preis 20 € inkl. Versandkosten</label>
                    </div>
                    <div>
                    <input type="checkbox" name="books" value="Was jetzt Anna?"/><label htmlFor="anna" style={{ marginLeft: '5px' }}                     > Was jetzt Anna?        - Preis 13 € inkl. Versandkosten</label>
                    </div>
                    <div>
                    <input type="checkbox" name="books" value="Chui Hatari"/><label htmlFor="chui-hatari" style={{ marginLeft: '5px' }}       > Chui Hatari            - Preis 13 € inkl. Versandkosten</label>
                    </div>
                </div>
                {/* <div className="mb-3">
                    Sobald der Kaufbetrag auf meinem Konto DE97 3701 0050 0098 6885 01 eingegangen ist, werde ich das Buch signieren und versenden. 
                </div> */}
                <div className="mb-3">
                    <label className="form-label" htmlFor="signature">
                    Für wen soll die Signatur sein?
                    </label>
                    <input className="form-control" type="text" id="signature" name="signature" required />
                </div>
                <div className="mb-3">
                    <label className="form-label" htmlFor="address">
                        Bitte sende mir Buch und Rechnung an folgende Adresse: 
                    </label>
                    <input className="form-control" type="text" id="address" name="address" required />
                </div>
                <Button variant="dark" type="submit" onClick={changeButtonColor.bind(this)}>
                    {formStatus}
                </Button>
            </form>
        </div>
    )
}
