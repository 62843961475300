import React from "react";
import { Row, Col, Button } from 'react-bootstrap';

const chapterOne = `„Hast du gesehen, Anna schmollt schon wieder. Wieso eigentlich?“ fragt Blacky. „Bin sicher, sie ist wieder unzufrieden mit uns,“ antwortet Whitey. „Aber was tun wir denn außer Fressen und herum hoppeln? Da kann sie doch nicht böse mit uns sein.“ „Ja, aber du weißt doch, 10-jährige Mädchen spinnen manchmal.“ „Woher soll ich das wissen, ich bin erst vier und du bist auch nur ein bisschen älter und kein Mädchen. Wieso glaubst du, dass sie spinnt?“ „Na, weil sie schon wieder hier im Garten umherturnt und Rad schlägt.“ 

Blacky und Whitey schauen interessiert zu, wie Anna ihre Turnübungen auf dem Rasen macht. „Iiih,“ schreit sie plötzlich. „Schon wieder Kaninchenkacke! Könnt ihr denn nicht in eurer Hütte kacken? Das ist doch eklig hier auf der Wiese.“ Die grün gefärbten Hände weit von sich streckend läuft sie zum Waschbecken und schrubbt sie gründlich ab. Blacky und Whitey schauen sie traurig an. 

Als Anna zurück auf die Wiese kommt, sitzen die beiden am Rand und kauen genüsslich an einem Kohlblatt. Anna beginnt wieder ihre Turnübungen und versucht sich an einem Handstand. Immer wieder fällt sie nach vorne oder hinten um. Es scheint ihr schwer zu fallen, die Balance im Handstand zu halten. 

„Das ist auch keine einfache Übung, ich könnte das auch nicht“, sagt Whitey. „Hast du es schon mal probiert?“ „Nö, aber das sieht mir zu schwierig aus, da will ich es gar nicht erst versuchen.“ Anna schaut zwischendurch zu den beiden und überlegt, was sie wohl im Sinn haben. „Können Kaninchen sprechen?“ fragt sie die beiden. Eine Antwort bekommt sie nicht. Stattdessen knabbern die beiden weiter an ihrem Kohlblatt und wechseln dann zu ein paar Mohrrüben. 

„Anna, gehst du bitte Hände waschen und kommst zum Abendessen,“ ruft plötzlich ihre Mutter aus der Küche. Blacky und Whitey sehen Anna nach, wie sie ins Haus verschwindet und fragen sich, ob Anna wohl bemerkt hat, dass sie nichts mehr zu trinken haben. „Meinst du, sie kommt noch mal raus und bringt uns was?“ fragt Blacky. „Ich glaube eher, dass ihre Eltern das übernehmen werden. Anna ist doch oft zu vergesslich.“ 

Tatsächlich kommen Klaus und Marion nach einiger Zeit in den Garten. Sie haben ein Getränk in der Hand und setzen sich auf die Gartenstühle. Dann reden die beiden miteinander, allerdings so leise, dass Blacky und Whitey nichts verstehen können. „Was flüstern die denn da?“ fragt Whitey. „Vielleicht gehts wieder um Annas Geburtstag. Sie erzählt doch schon seit Wochen, dass sie zum Geburtstag Meerschweinchen bekommen soll. Als ob wir nicht genug sind. Sie schafft es kaum, sich dauerhaft um uns zu kümmern. Die Meerschweinchen können einem jetzt schon leidtun.“ 

Dann steht Klaus auf und kommt zum Kaninchenstall. Er sortiert die verschiedenen Futterreste aus, ordnet das Stroh und füllt den Wasserbehälter. Zum Schluss sagt er: „Ich wünsche euch eine gute Nacht ihr beiden“, und hängt ein großes Tuch über den Käfig.`

const chapterTwo = `‚Die fünf Freunde Geschichte ist ganz schön spannend. Ich muss das Buch möglichst bald auslesen, damit ich weiß, ob die fünf den Verbrecher fangen. Aber ich kann mich gar nicht so richtig konzentrieren. Immer muss ich an meinen Geburtstag in vier Wochen denken‘ überlege ich. In der Schule heute, habe ich auch wieder dauernd die Meerschweinchen im Kopf. Sogar in Englisch, wo wir gerade ein Kapitel über einen Bauernhof lesen, habe ich auf die Frage der Lehrerin: „Which animals live on a farm?“ „Guinea pigs“, geantwortet. Alle anderen haben mich ausgelacht und die Lehrerin fragte: „Did you meet guinea pigs on a farm? Or do you mean real pigs?“ Das war ziemlich peinlich und ich wurde rot bis unter die Haarspitzen. Zum Glück fiel mir noch ein, dass auf dem Bauernhof außer Schweinen auch Kühe und Hühner leben. Gut, dass ich das auch gerade noch auf Englisch sagen konnte.  

In Sachunterricht haben wir das Thema Haustiere. Wir sollten für heute eine Sammlung von Haustieren malen. Was denkst du, hatte ich gezeichnet? Natürlich waren zwei Meerschweinchen die beiden größten Tiere auf meinem Bild. Hunde, Katzen, Kaninchen, Wellensittiche und Hamster schauten von außen zu. Bei uns sind zwar zwei Kaninchen im Garten, aber die empfinde ich als Teil der Familie, nicht als Haustiere. Jedenfalls nicht als meine.

Jetzt aber zurück zu den fünf Freunden. Timmy und Ju beobachten gerade ein Haus im Wald, in dem sie den Verbrecher vermuten. Das ist gruselig und ich höre jetzt lieber mal auf und lese morgen im Hellen weiter.

In der Nacht begegne ich den beiden Meerschweinchen, die wir letztens in der Zoohandlung gesehen haben. James, ein US -Teddy aus Amerika war so süß. Aber auch Gloria, ein Crested Guinea Pig ist putzig. Ich weiß gar nicht, welches der beiden ich lieber möchte. Am liebsten beide. Dann klingelt der Wecker und ich muss ins Bad.

Beim Frühstück schwärme ich Mama von meinem Traum vor. „Die beiden kuscheligen Tiere waren so toll. Wann können wir die abholen?“ „Nun mal langsam junge Dame, du weißt, dass wir uns zuerst noch mehr mit der Haltung der Tiere beschäftigen müssen.“ „Wieso, ich weiß doch schon alles. Man braucht einen großen Käfig, muss auf die Verträglichkeit der beiden Tiere achten und sich viel mit ihnen beschäftigen. Auch was sie fressen, habe ich gegoogelt.“ „Das kann schon sein, aber die Fachleute im Laden müssen auch ihr Okay geben. Es ist ja noch etwas Zeit bis zu deinem Geburtstag.“ „Wann holen wir sie denn endlich?“ „Wie ich dir schon ein paarmal gesagt habe, müssen wir zunächst mal die Käfiggröße festlegen und einen entsprechenden Käfig aussuchen.“ „Können wir das denn heute nach der Schule machen?“ „Nein heute habe ich keine Zeit, ich muss bis 17 Uhr arbeiten und dann ist es für den Besuch im Zoogeschäft zu spät.“ „Okay, dann also morgen?“ „Mal sehen, vielleicht kann Papa mit dir fahren.“`

const chapterThree = `„Du möchtest also zwei Meerschweinchen?“ fragt Herr Schulze in der Zoohandlung. „Ja, die bekomme ich zum Geburtstag“, antwortet Anna. „Wir brauchen zuerst noch ihren Rat wegen des Käfigs. Wie groß muss der denn sein bei zwei Meerschweinchen?“ will Annas Vater wissen. Herr Schulze erklärt: „Meerschweinchen sind sehr aktive Tiere. Man braucht einen Käfig, in dem sie sich gut bewegen können. Außerdem müssen sie häufig draußen herumlaufen, sonst werden sie traurig oder aggressiv. Bei zwei Tieren empfehle ich immer eine Behausung, die mindestens 180 mal 120 cm groß ist. Das Zuhause der Tiere sollte nicht in einem Schlafzimmer sein, weil die Tiere auch nachts manchmal aktiv sind.“ „Das ist ja doof,“ sagt Anna. „Das heißt ja sie können nicht in meinem Zimmer sein und Mama will sie nicht im Wohnzimmer haben. Was machen wir denn jetzt?“ „Das müssen wir zu Hause alle zusammen überlegen“, antwortet Annas Vater. „Kann ich denn noch mal die Meerschweinchen anschauen?“, bittet Anna. „Ja sicher“, sagt Herr Schulze, „komm mit, ich zeige sie dir.“ James und Gloria, die sie beim letzten Besuch angeschaut hatte, sind inzwischen verkauft, aber Herr Schulze zeigt ihr zwei andere Exemplare.

Die beiden Tiere sind ganz aufgeregt. „Das Mädchen schaut so aus, als ob sie uns mitnehmen möchte,“ sagt Tina zu Bibi. „Du hast recht, so wie sie uns ansieht, würde sie uns am liebsten gleich einpacken. Die war doch letztens schon mal da. Ich glaube, wenn die den passenden Käfig finden, könnten wir Glück haben. Dann hätten wir bald ein richtiges Zuhause.“ 

Anna steht vor dem Gehege mit den Meerschweinchen und sieht sich die Exemplare genau an. „Sieh mal, Papa, die beiden sind genau solche, wie ich sie mir wünsche. Darf ich sie mal anfassen, Herr Schulze?“ „Ja, wenn du vorsichtig bist. Denn Bibi und Tina sind sehr scheu.“ Er öffnet die Käfig Tür, nimmt Bibi heraus und gibt sie Anna. Sie streichelt das braun-weiße Fell und ist sehr glücklich. Bibi fühlt sich offensichtlich wohl auf ihrem Arm und genießt es, gestreichelt zu werden. Als sie wieder in den Käfig zurückkommt, sagt sie zu Tina: „Die ist supernett, bei der würde ich sehr gern wohnen.“ Als dann Herr Schulze auch Tina herausnimmt und in Annas Arme legt, sind sowohl Anna als auch Tina sehr zufrieden.  „Ihr Fell ist noch flauschiger“ sagt Anna zu ihrem Vater.  

Dann gehen Anna und Klaus wieder weg und Anna winkt Bibi und Tina noch mal zu. Nun sind die beiden ein wenig enttäuscht. „Wieso hat sie uns jetzt zurückgelassen?“ fragt Tina. „Ich dachte sie mag uns gern.“ „Keine Ahnung“, erwidert Bibi. „Ich glaube, wir müssen uns noch etwas gedulden. Aber sieh mal, Herr Schulze hat uns eine Extra Portion Leckerlies dagelassen. Mjam.“ 

Schon knabbern die beiden genüsslich an den gepressten Heuballen. „Köstlich“, schmatzt Tina. „Die könnt ich dauernd essen.“ „Ich mag die getrockneten Gemüseblätter noch lieber, aber leider gibt’s die nicht so oft.“ „Meinst du, dass Anna uns auch solche Leckerlis geben würde?“ 

Die beiden sehen nicht, dass Anna und ihr Vater im Nebenraum noch lange mit Herrn Schulze sprechen. Anna möchte alles wissen, was sie noch nicht im Internet gefunden hat. „Wieviel Futter bekommen denn die Meerschweinchen? Wie oft müssen sie aus dem Käfig herausgelassen werden und für wie lange? Welche Krankheiten können sie bekommen?“ Herr Schulze beantwortet alles geduldig und lobt Anna für ihr Interesse. „Ich bin sicher, bei dir würden Bibi und Tina es gut haben. Du willst offensichtlich, dass sie sich bei dir wohlfühlen.“ „Wir müssen zuerst überlegen und entscheiden, wo der Käfig der beiden stehen kann“, sagt Annas Vater. Sobald das geklärt ist, können wir die Tiere holen.“ „Na dann viel Spaß beim Diskutieren und ich freue mich, wenn ihr bald wiederkommt. Bibi und Tina sicher auch.“

Bibi und Tina haben sich inzwischen zum Schlafen hingelegt. Sie träumen beide davon, wie sie abgeholt werden und Anna sie stolz in ihrem neuen Zuhause begrüßt und ihnen Wasser und Futter hinstellt.`

const chapterFour = `Beim Abendessen besprechen wir die Lage noch einmal eingehend. „Wo können wir denn die Meerschweinchen unterbringen? will ich wissen. Mama sagt nachdenklich: „Im Wohnzimmer möchte ich sie auf keinen Fall. Deshalb haben wir ja damals die Kaninchen nach draußen gebracht, weil ich es satt hatte, ständig ihren Dreck weg machen zu müssen. Und wenn sie nicht in einem Schlafzimmer sein sollen, weiß ich auch nicht, wo sie dann leben können.“ „Im Keller ist es zu dunkel und zu kalt und auf dem Dachboden zu heiß,“ wirft Papa ein. Ich habe Tränen in den Augen und schluchze: „Können sie denn nicht im Gartenhaus wohnen?“ „Das geht auch nicht,“ sagt der Papa. „Dort sind die Temperaturschwankungen zu groß.“ „Aber die Kaninchen leben auch draußen. Wieso nicht die Meerschweinchen?“, protestiere ich. „Kaninchen leben sonst auch im Freien, aber die Meerschweinchen halten unser Klima nicht aus.“ „Kann ich dann gar keine Meerschweinchen bekommen?“ „Wenn wir keine Lösung für den Käfig finden, wohl eher nicht.“

Soviel wir auch nachdenken, es will uns keine Möglichkeit einfallen. Meine allerletzte Idee, doch einen Platz im Badezimmer zu schaffen, ist ebenfalls nicht umzusetzen. „Da ist doch zu viel Luftfeuchtigkeit. Das Heu könnte schimmeln und dann werden deine Lieblinge krank“, winkt Mama ab. Da fällt mir ein, dass Karla einen Hamster hat und ich starte einen letzten Versuch, meine Eltern umzustimmen: „Wisst ihr eigentlich, dass Karlas Hamsterkäfig in ihrem Zimmer steht? Warum dürfen Hamster ins Kinderzimmer und Meerschweinchen nicht? Das ist doch voll unfair!“ Jetzt laufen mir schon Tränen über meine Wangen. Die beiden sind überrascht und wundern sich sehr darüber. „Hm, eigentlich sollte das nicht so sein. Hamster sind genauso auch nachts aktiv und stören den Schlaf.“ erklärt Mama. „Am besten fragst du sie morgen, warum das so ist. Vielleicht gibt es einen guten Grund dafür.“ 

Es ist spät geworden bei unserer Debatte, ich sollte seit einer halben Stunde im Bett sein. Traurig schleiche ich ins Bad und dann in mein Zimmer. Mama ruft mir noch nach: „Sei nicht so traurig, Schatz! Wir werden schon ein liebes Haustier für dich finden. Hab ein bisschen Geduld!“

Am nächsten Morgen, in der Schule überfalle ich Karla gleich mit meinen vielen Fragen. Ich möchte alles wissen: „Warum erlauben deine Eltern, dass der Hamster in deinem Zimmer wohnt?... Weckt er dich nachts nicht auf?... Wie lange musstest du deine Eltern überreden, dass du ein Tier bekommst?... Musst du dein Tier auch alleine versorgen, oder hilft dir jemand dabei?...“  Es gibt so viel, das ich wissen will. Es ist richtig schwer, nur in den Pausen darüber zu reden und sich in den Unterrichtstunden zu konzentrieren. Aber Karlas Antworten zeigen mir, dass Mama, Papa und Herr Schulze mir keine Märchen erzählen. Ich habe gesehen, dass der Hamsterkäfig, der übrigens viel kleiner ist, als der, den ich bräuchte, im Kinderzimmer steht. Was ich aber bisher nicht wusste: abends übersiedelt der kleine Charly auf den Flur. Dort ist genug Platz und da stört er niemanden beim Schlafen. Karla erzählt mir, dass sie auch lange warten musste. Irgendwann haben ihre Eltern nachgegeben, weil ihr älterer Bruder doch den Hund bekommen hatte. Deshalb macht es Karla gar nichts aus, dass sie fast alles allein machen muss. „Manchmal hilft mir Felix beim Käfig Putzen, dafür gehe ich schon mal mit Lassie Gassi“, erzählt sie mir. 

Also bin ich jetzt so gescheit wie vorher und berichte Mama und Papa von unserem Gespräch.`

function wrapCol(elem){
    return <Row><Col>{elem}</Col></Row>;
}

function linesToRow(lines){
    return lines.split("\n").map((line) => wrapCol(line))
}

export default function AnnaRows() {

    const data = [
        wrapCol(<h1>Was jetzt, Anna?</h1>),
        wrapCol(<h4>Blacky und Whitey</h4>),
        linesToRow(chapterOne), 
        wrapCol(<h4>Anna</h4>),       
        linesToRow(chapterTwo),
        wrapCol(<h4>In der Zoohandlung</h4>),       
        linesToRow(chapterThree),
        wrapCol(<h4>Anna</h4>),       
        linesToRow(chapterFour),
        wrapCol(<Button variant="dark" block><a href="/kaufen" className="White-Link">Direkt beim Autor kaufen</a></Button>),
        wrapCol(<Button variant="dark" block><a href="https://www.amazon.de/Was-jetzt-Anna-Jove-Viller/dp/B08VCN6BTJ/ref=sr_1_6?__mk_de_DE=ÅMÅŽÕÑ&dchild=1&keywords=Bücher%3A+Was+jetzt+Anna%3F&qid=1616404008&sr=8-6" target="_blank" rel="noopener noreferrer" className="White-Link">Bei Amazon kaufen</a></Button>)   
    ];
    const divText = data.map((line) => <p>{line}</p>);

    return (
        <div class="text-justify">{divText}</div> 
    ); 

};