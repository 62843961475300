import React from "react";
import Container from 'react-bootstrap/Container';

import { ImpressumRows } from './ImpressumBuilder';

export default function Impressum() {
    return (
        <div className="Impressum">

            <Container>           
                <ImpressumRows />
            </Container>
            
        </div>
        );
}
